import { styled } from '@mui/material';

const CenteredCell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
}));

CenteredCell.displayName = 'CenteredCell';

export default CenteredCell;
