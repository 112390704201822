import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { styled, SxProps } from '@mui/material';
import React from 'react';
import { Row as ReactTableRow } from 'react-table';

const Tr = styled('tr', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'odd' && propName !== 'clickable',
})<{ odd: boolean; clickable?: boolean }>(({ theme, clickable, odd }) => ({
  background: odd ? 'rgba(0, 0, 0, .05)' : undefined,
  '&:hover': {
    background: odd ? 'rgba(0, 0, 0, .075)' : 'rgba(0, 0, 0, .025)',
  },
  cursor: clickable ? 'pointer' : 'normal',
}));

Tr.displayName = 'Tr';

const Td = styled('td', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  border: '1px solid rgba(0, 0, 0, .05)',
  padding: '2px 4px',
  backgroundColor: selected ? 'rgb(60 190 255 / 15%)' : undefined,
  whiteSpace: 'nowrap',
}));

Td.displayName = 'Td';

interface OwnProps {
  row: ReactTableRow;
  expanded?: boolean;
  index: number;
  totalColumns?: number;

  onRowClick?(row: ReactTableRow);
  renderRowSubComponent?(row: ReactTableRow);
  getRowSx?(row: ReactTableRow): SxProps;
}

type Props = OwnProps;

const Row: React.FunctionComponent<Props> = ({
  row,
  index,
  onRowClick,
  renderRowSubComponent,
  totalColumns,
  getRowSx,
}) => {
  const onRowClickCallback = useEventCallback(() => {
    if (onRowClick) {
      onRowClick(row);
    }
  });

  return (
    <>
      <Tr
        odd={index % 2 === 1}
        clickable={Boolean(onRowClick)}
        data-row=""
        onClick={onRowClickCallback}
        {...row.getRowProps()}
        sx={getRowSx?.(row)}
      >
        {row.cells.map((cell) => (
          // eslint-disable-next-line react/jsx-key
          <Td selected={row.isSelected} {...cell.getCellProps()} data-column="">
            {cell.render('Cell')}
          </Td>
        ))}
      </Tr>
      {row.isExpanded && renderRowSubComponent ? (
        <Tr odd={index % 2 === 1}>
          <td colSpan={totalColumns}>{renderRowSubComponent(row)}</td>
        </Tr>
      ) : null}
    </>
  );
};

Row.displayName = 'Row';

export default Row;
// Cannot memoize because row is mutated in-place
// export default React.memo(Row);
