import Interpolate from '@engined/client/components/Interpolate.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import {
  REACT_TABLE_NEXT,
  REACT_TABLE_PAGE,
  REACT_TABLE_PREVIOUS,
  REACT_TABLE_ROWS,
  REACT_TABLE_TOTAL_COUNT,
} from '@engined/client/locales.js';
import {
  FormControl,
  inputBaseClasses,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  formControlClasses,
} from '@mui/material';
import Input from '@engined/client/components/table/Input.js';
import React, { useEffect, useState } from 'react';

const Root = styled('div')(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  padding: '3px',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, .1)',
  borderTop: '2px solid rgba(0, 0, 0, .1)',
}));

Root.displayName = 'Root';

const NextPreviousContainer = styled('div')(({ theme }) => ({
  flex: '1',
  textAlign: 'center',
}));

NextPreviousContainer.displayName = 'NextPreviousContainer';

const Center = styled('div')(({ theme }) => ({
  flex: '1.5',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
}));

Center.displayName = 'Center';

const PageInfo = styled('span')(({ theme }) => ({
  margin: '3px 10px',
  whiteSpace: 'nowrap',
  display: 'inline-block',
}));

const PageInfoInput = styled(Input)(({ theme }) => ({
  [`&.${inputBaseClasses['root']}`]: {
    width: 70,
  },
  [`& .${inputBaseClasses['input']}`]: {
    textAlign: 'center',
  },
}));

PageInfo.displayName = 'PageInfo';

const PageSize = styled('span')(({ theme }) => ({
  margin: '3px 10px',
  display: 'inline-flex',
  alignItems: 'center',
  [`& .${formControlClasses['root']}`]: {
    marginRight: theme.spacing(1),
  },
}));

PageSize.displayName = 'PageSize';

const Button = styled('button')(({ theme }) => ({
  appearance: 'none',
  display: 'block',
  width: '100%',
  height: '100%',
  border: '0',
  borderRadius: '3px',
  padding: '6px',
  fontSize: '1em',
  color: 'rgba(0, 0, 0, .6)',
  backgroundColor: 'rgba(0, 0, 0, .1)',
  transition: 'all .1s ease',
  cursor: 'pointer',
  outline: 'none',

  '&:disabled': { opacity: '.5', cursor: 'default' },
}));

Button.displayName = 'Button';

interface OwnProps {
  className?: string;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
  pageSize: number;
  pages: number;
  totalCount?: number;

  previousPage();
  nextPage();
  gotoPage(page: number);
  setPageSize(pageSize: number);
}

type Props = OwnProps;

const Pagination: React.FunctionComponent<Props> = ({
  className,
  canNextPage,
  canPreviousPage,
  previousPage,
  nextPage,
  gotoPage,
  pageIndex,
  pageSize,
  setPageSize,
  pages,
  totalCount,
}) => {
  const { t } = useLocale();
  const [localPageIndex, setLocalPageIndex] = useState<string>((pageIndex + 1).toString());
  const [prevPageIndex, setPrevPageIndex] = useState<number>(pageIndex);

  if (prevPageIndex !== pageIndex) {
    setPrevPageIndex(pageIndex);
    setLocalPageIndex((pageIndex + 1).toString());
  }

  const onPageIndexChange = useEventCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalPageIndex(event.currentTarget.value);
  });

  const onPreviousPage = useEventCallback(() => previousPage());
  const onNextPage = useEventCallback(() => nextPage());
  const onSetPageSize = useEventCallback((event: SelectChangeEvent<number>) => setPageSize(Number(event.target.value)));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!/\d+/.test(localPageIndex)) {
        // Ignore - not a number
        return;
      }

      let wanted = parseInt(localPageIndex, 10) - 1;
      if (wanted > pages) {
        wanted = pages - 1;
      } else if (wanted < 0) {
        wanted = 0;
      }

      gotoPage(wanted);
    }, 50);

    return () => clearTimeout(timer);
  }, [localPageIndex, gotoPage, pages]);

  return (
    <Root className={className}>
      <NextPreviousContainer>
        <Button type="button" onClick={onPreviousPage} disabled={!canPreviousPage}>
          {t(REACT_TABLE_PREVIOUS)}
        </Button>
      </NextPreviousContainer>
      <Center>
        <PageInfo>
          <Interpolate
            resource={REACT_TABLE_PAGE}
            pages={pages}
            components={[<PageInfoInput key="0" type="number" value={localPageIndex} onChange={onPageIndexChange} />]}
          />
        </PageInfo>
        <PageSize>
          <FormControl variant="outlined" margin="none">
            <Select value={pageSize} onChange={onSetPageSize} margin="none" input={<Input />}>
              {[10, 20, 30, 40, 50, 100, 200, 500, 1000].map((ps) => (
                <MenuItem key={ps} value={ps}>
                  {t(REACT_TABLE_ROWS, { count: ps })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {totalCount > 0 && <Interpolate resource={REACT_TABLE_TOTAL_COUNT} count={totalCount} />}
        </PageSize>
      </Center>
      <NextPreviousContainer>
        <Button type="button" onClick={onNextPage} disabled={!canNextPage}>
          {t(REACT_TABLE_NEXT)}
        </Button>
      </NextPreviousContainer>
    </Root>
  );
};

Pagination.displayName = 'Pagination';

export default React.memo<Props>(Pagination);
