import { InputBase, inputBaseClasses, InputBaseProps, styled } from '@mui/material';

const Input = styled(InputBase)(({ theme }) => ({
  [`&.${inputBaseClasses['root']}`]: {
    width: '100%',
  },
  [`& .${inputBaseClasses['input']}`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    padding: theme.spacing(1, 2, 1, 1),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

Input.displayName = 'Input';

export default Input;

export type InputProps = InputBaseProps;
