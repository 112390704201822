import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import {
  REACT_TABLE_VISIBILITY_CLOSE,
  REACT_TABLE_VISIBILITY_HEADER,
  REACT_TABLE_VISIBILITY_TOGGLE_ALL,
} from '@engined/client/locales.js';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import React from 'react';
import { ColumnInstance, TableToggleHideAllColumnProps } from 'react-table';

interface OwnProps<D extends Record<string, unknown>> {
  className?: string;
  open: boolean;
  allColumns: Array<ColumnInstance<D>>;
  toggleHideAllColumnsProps: TableToggleHideAllColumnProps;
  onClose();
}

type Props = OwnProps<Record<string, unknown>>;

const VisibilityDialog: React.FunctionComponent<Props> = ({
  className,
  open,
  onClose,
  toggleHideAllColumnsProps,
  allColumns,
}) => {
  const { t } = useLocale();

  return (
    <Dialog className={className} open={open} onClose={onClose}>
      <DialogTitle>{t(REACT_TABLE_VISIBILITY_HEADER)}</DialogTitle>
      <DialogContent>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                {...toggleHideAllColumnsProps}
                indeterminate={Boolean(toggleHideAllColumnsProps.indeterminate)}
              />
            }
            label={t(REACT_TABLE_VISIBILITY_TOGGLE_ALL)}
          />
        </div>
        {allColumns.map((column) => (
          <div key={column.id}>
            <FormControlLabel
              control={<Checkbox color="primary" {...column.getToggleHiddenProps()} />}
              label={<>{column.render('Header')}</>}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t(REACT_TABLE_VISIBILITY_CLOSE)}</Button>
      </DialogActions>
    </Dialog>
  );
};

VisibilityDialog.displayName = 'VisibilityDialog';

export default React.memo(VisibilityDialog);
