import Input, { InputProps } from '@engined/client/components/table/Input.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import React, { useEffect, useRef, useState, useTransition } from 'react';
import { FilterProps } from 'react-table';

function InputFilter<D extends object>({ column }: FilterProps<D>) {
  const { filterValue, setFilter } = column;
  const [stateValue, setStateValue] = useState<string>(filterValue || '');
  const [isPending, startTransaction] = useTransition();
  const timerRef = useRef<number>(null);

  const onChangeCallback = useEventCallback<InputProps['onChange']>((event) => {
    clearTimeout(timerRef.current);
    const value = event.currentTarget.value;
    timerRef.current = window.setTimeout(() => {
      startTransaction(() => {
        setFilter(value);
      });
    }, 300);

    setStateValue(value);
  });

  useEffect(() => {
    setStateValue(filterValue || '');
  }, [filterValue, setStateValue]);

  return (
    <Input
      type="text"
      value={stateValue}
      onChange={onChangeCallback}
      placeholder={(column as any).Placeholder}
      name={column.id}
    />
  );
}

InputFilter.displayName = 'InputFilter';

InputFilter.filterToGraphQLVariable = (value) => ({
  ilike: `%${value}%`,
  unaccent: true,
});

export default InputFilter;
