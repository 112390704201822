import { DocumentNode } from '@apollo/client/index.js';
import useUserSettings from '@asaprint/asap/hooks/useUserSettings.js';
import InputFilter from '@engined/client/components/table/InputFilter.js';
import Pagination from '@engined/client/components/table/Pagination.js';
import Row from '@engined/client/components/table/Row.js';
import VisibilityDialog from '@engined/client/components/table/VisibilityDialog.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import useAsyncDebounce from '@engined/client/hooks/useAsyncDebounce.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import {
  REACT_TABLE_COLUMNS_VISIBLITY,
  REACT_TABLE_EXPORT_XLSX,
  REACT_TABLE_LOADING,
  REACT_TABLE_NO_RESULTS,
  REACT_TABLE_PRINT,
  REACT_TABLE_RESET_FILTERS,
} from '@engined/client/locales.js';
import { scrollbar } from '@engined/client/styles/mixins.js';
import { faEye, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faFilter, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, styled } from '@mui/material';
import { Location } from 'history';
import { omit, set } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import queryString from 'query-string';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  actions,
  Column,
  Filters,
  Row as ReactTableRow,
  TableState,
  useExpanded,
  useFilters,
  UseFiltersInstanceProps,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

export interface ReactTableRef<TData extends Record<string, unknown>> {
  setAllFilters: UseFiltersInstanceProps<TData>['setAllFilters'];
}

const FILTER_PREFIX = (prefix) => `${prefix}rtf-`;
const SORT_PREFIX = (prefix) => `${prefix}rts-`;
const WIDTH_PREFIX = (prefix) => `${prefix}rtw-`;
const HIDDEN_PREFIX = (prefix) => `${prefix}rth-`;
const EXPANDED_PREFIX = (prefix) => `${prefix}rte-`;
const PAGE = (prefix) => `${prefix}rtp`;
const PAGE_SIZE = (prefix) => `${prefix}rtps`;
const CACHE_KEY = (id) => `rt-v3-${id}`;

const emptySelectedRowIDs = {};

function calculateInitialState(
  id: OwnProps['id'],
  urlPrefix: OwnProps['urlPrefix'],
  defaultPageIndex: OwnProps['defaultPageIndex'],
  defaultPageSize: OwnProps['defaultPageSize'],
  defaultSortBy: OwnProps['defaultSortBy'],
  defaultFilters: OwnProps['defaultFilters'],
  defaultHiddenColumns: OwnProps['defaultHiddenColumns'],
  location: Location,
  columns: Column<Record<string, any>>[],
): TableState {
  // const storage = getLocalStorage();
  // if (storage && id && !location.search) {
  //   const stored = storage.getItem(CACHE_KEY(id));
  //   if (stored) {
  //     try {
  //       return JSON.parse(stored);
  //     } catch (err) {
  //       // Nothing
  //     }
  //   }
  // }

  const columnKeys = columns.map((c) => c.id || (c.accessor as string));

  const query = queryString.parse(location.search);
  const keys = Object.keys(query);
  const filter: TableState['filters'] = keys.reduce<TableState['filters']>((acc, cur) => {
    if (!cur.startsWith(FILTER_PREFIX(urlPrefix))) {
      return acc;
    }

    const id = cur.replace(new RegExp(`^${FILTER_PREFIX(urlPrefix)}`), '');
    if (!columnKeys.includes(id)) {
      return acc;
    }

    return acc.concat({ id, value: query[cur] });
  }, []);

  const sortBy: TableState['sortBy'] = keys.reduce<TableState['sortBy']>((acc, cur) => {
    if (!cur.startsWith(SORT_PREFIX(urlPrefix))) {
      return acc;
    }

    const id = cur.replace(new RegExp(`^${SORT_PREFIX(urlPrefix)}`), '');
    if (!columnKeys.includes(id)) {
      return acc;
    }

    return acc.concat({
      id: cur.replace(new RegExp(`^${SORT_PREFIX(urlPrefix)}`), ''),
      [query[cur] as 'desc']: true,
    });
  }, []);

  const columnWidths: TableState['columnResizing']['columnWidths'] = keys
    .filter((key) => key.startsWith(WIDTH_PREFIX(urlPrefix)))
    .reduce<TableState['columnResizing']['columnWidths']>((acc, cur) => {
      acc[cur.replace(new RegExp(`^${WIDTH_PREFIX(urlPrefix)}`), '')] = parseInt(query[cur] as string, 10);
      return acc;
    }, {});

  const hiddenColumns: TableState['hiddenColumns'] = keys
    .filter((key) => key.startsWith(HIDDEN_PREFIX(urlPrefix)))
    .reduce<TableState['hiddenColumns']>(
      (acc, cur) => acc.concat(cur.replace(new RegExp(`^${HIDDEN_PREFIX(urlPrefix)}`), '')),
      [],
    );

  const expanded: TableState['expanded'] = keys
    .filter((key) => key.startsWith(EXPANDED_PREFIX(urlPrefix)))
    .reduce<TableState['expanded']>((acc, cur) => {
      acc[cur.replace(new RegExp(`^${EXPANDED_PREFIX(urlPrefix)}`), '')] = true;
      return acc;
    }, {});

  let pageSize = 10;
  if (query[PAGE_SIZE(urlPrefix)]) {
    pageSize = parseInt(query[PAGE_SIZE(urlPrefix)] as string, 10);
  } else if (defaultPageSize) {
    pageSize = defaultPageSize;
  }

  return {
    sortBy: !location.search ? defaultSortBy || [] : sortBy,
    pageIndex:
      query[PAGE(urlPrefix)] !== undefined
        ? Math.max(parseInt(query[PAGE(urlPrefix)] as string, 10), 1) - 1
        : defaultPageIndex,
    pageSize,
    // resized: defaultResized || [],
    filters: !location.search ? defaultFilters || [] : filter,
    selectedRowIds: emptySelectedRowIDs,
    columnOrder: [],
    expanded,
    groupBy: [],
    columnResizing: {
      columnWidth: null,
      columnWidths,
      headerIdWidths: columnWidths,
      startX: null,
      isResizingColumn: null,
    },
    globalFilter: null,
    rowState: {},
    hiddenColumns: !location.search ? defaultHiddenColumns || [] : hiddenColumns,
  };
}

function normalizeInitialState(state: TableState, columns: Column<Record<string, any>>[]): TableState {
  const columnKeys = columns.map((c) => c.id || (c.accessor as string));
  return {
    ...state,
    sortBy: state.sortBy.filter((s) => columnKeys.includes(s.id)),
    filters: state.filters.filter((s) => columnKeys.includes(s.id)),
  };
}

const getTableBodyProps = (props, { instance }) => [
  props,
  {
    style: {
      minWidth: `${instance.totalColumnsWidth}px`,
    },
  },
];

const getRowStyles = (props, { instance }) => [
  props,
  {
    style: {
      // display: 'flex',
      // flex: '1 0 auto',
      minWidth: `${instance.totalColumnsMinWidth}px`,
    },
  },
];

const getHeaderProps = (props, { column }) => [
  props,
  {
    style: {
      // boxSizing: 'border-box',
      // flex: column.totalFlexWidth ? `${column.totalFlexWidth} 0 auto` : undefined,
      minWidth: `${column.totalMinWidth}px`,
      width: `${column.totalWidth}px`,
    },
  },
];

const getCellProps = (props, { cell }) => [
  props,
  {
    style: {
      // boxSizing: 'border-box',
      // flex: `${cell.column.totalFlexWidth} 0 auto`,
      minWidth: `${cell.column.totalMinWidth}px`,
      width: `${cell.column.totalWidth}px`,
    },
  },
];

export function useTableLayout(hooks): void {
  hooks.getTableBodyProps.push(getTableBodyProps);
  hooks.getRowProps.push(getRowStyles);
  hooks.getHeaderGroupProps.push(getRowStyles);
  hooks.getHeaderProps.push(getHeaderProps);
  hooks.getCellProps.push(getCellProps);
}

useTableLayout.pluginName = 'useTableLayout';

function fuzzyTextFilterFn(rows: any[], id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

function cloneTable(table: HTMLTableElement): HTMLTableElement {
  const clone = table.cloneNode(true) as HTMLTableElement;
  const filters = clone.querySelector(`.${classes.filters}`);
  if (filters) {
    filters.remove();
  }
  return clone;
}

const stateReducer = (newState, action, prevState) => {
  switch (action.type) {
    case actions.resetExpanded:
      return {
        ...newState,
        // Do not reset to initialState https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/useExpanded.js#L52
        expanded: {},
      };
    case actions.resetPage:
      return {
        ...newState,
        // Do not reset to initialState https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/usePagination.js#L40
        pageIndex: 0,
      };
    default:
      return newState;
  }
};

const TableResponsive = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  overflowX: 'auto',
  overflowScrolling: 'touch',
  overflowY: 'hidden',
  ...scrollbar,
}));

TableResponsive.displayName = 'TableResponsive';

const Table = styled('table')(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, .1)',
  borderSpacing: 0,
  minWidth: 'calc(100% - 1px)',
  borderCollapse: 'collapse',
}));

Table.displayName = 'Table';

const Loading = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.75)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 100,
}));

Loading.displayName = 'Loading';

const HeaderRow = styled('tr')(({ theme }) => ({
  boxShadow: '0 2px 15px 0 rgba(0, 0, 0, .15)',
}));

HeaderRow.displayName = 'HeaderRow';

const HeaderCell = styled('th')(({ theme }) => ({
  padding: 0,
}));

HeaderCell.displayName = 'HeaderCell';

const HeaderResizer = styled('div')(({ theme }) => ({
  width: '10px',
  height: '32px',
}));

HeaderResizer.displayName = 'HeaderResizer';

const HeaderLabel = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: 5,
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

HeaderLabel.displayName = 'HeaderLabel';

const HeaderCellContent = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'sortDirection',
})<{ sortDirection?: 'asc' | 'desc' | 'none' }>(({ theme, sortDirection }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none',
  transition: 'box-shadow .3s cubic-bezier(.175, .885, .32, 1.275)',
  border: '1px solid rgba(0, 0, 0, .05)',
  minHeight: 35,
  boxShadow:
    sortDirection === 'none'
      ? 'inset 0 0 0 0 transparent'
      : `inset 0 ${sortDirection === 'asc' ? '' : '-'}3px 0 0 rgba(0, 0, 0, .6)`,
}));

HeaderCellContent.displayName = 'HeaderCellContent';

const Footer = styled('tfoot')(({ theme }) => ({
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, .1)',
  borderTop: '2px solid rgba(0, 0, 0, .1)',
}));

Footer.displayName = 'Footer';

const FooterCell = styled('th')(({ theme }) => ({
  padding: '5px 7px',
}));

FooterCell.displayName = 'FooterCell';

const FilterCell = styled('td')(({ theme }) => ({
  padding: 5,
  border: '1px solid rgba(0, 0, 0, 0.05)',
}));

FilterCell.displayName = 'FilterCell';

const TopScrollbar = styled('td')(({ theme }) => ({
  padding: 0,
}));

TopScrollbar.displayName = 'TopScrollbar';

const NoResults = styled('td')(({ theme }) => ({
  padding: theme.spacing(2, 4, 2, 8),
}));

NoResults.displayName = 'NoResults';

const LoadingError = styled('td')(({ theme }) => ({
  padding: theme.spacing(2, 4, 2, 8),
  color: theme.palette.error.main,
}));

LoadingError.displayName = 'LoadingError';

const classes = {
  headerLabel: 'ReactTable-HeaderLabel',
  filters: 'ReactTable-Filters',
};

interface OwnProps<TData extends Record<string, unknown> = Record<string, unknown>> {
  columns: Column<TData>[];
  data: TData[];
  id: string;
  urlPrefix?: string;
  ignoreUrl?: boolean;
  hideToolbar?: boolean;
  autoResetExpanded?: boolean;
  defaultSortBy?: TableState['sortBy'];
  defaultFilters?: TableState['filters'];
  defaultPageSize?: TableState['pageSize'];
  defaultPageIndex?: TableState['pageIndex'];
  defaultHiddenColumns?: TableState['hiddenColumns'];
  loading?: boolean;
  error?: React.ReactNode;
  pageCount?: number;
  initialState?: TableState;
  totalCount?: number;
  queryData?: unknown;
  aggregateData?: unknown;
  canPrint?: boolean;
  canXLSXExport?: boolean;
  canChangeColumnsVisibility?: boolean;
  disableFilters?: boolean;
  disablePagination?: boolean;
  load?(state: TableState); // Manual data fetching
  onRowClick?(row: ReactTableRow<TData>);
  renderRowSubComponent?(row: ReactTableRow<TData>);
  onSelectedChange?(rows: ReactTableRow<TData>[]);
  getRowSx?(row: ReactTableRow<TData>);
}

const emptyArray = [];

export type Props<TData extends Record<string, unknown> = Record<string, unknown>> = OwnProps<TData>;

const ReactTable: React.ForwardRefRenderFunction<ReactTableRef<Props['data'][0]>, Props> = (
  {
    columns,
    id,
    urlPrefix = '',
    ignoreUrl = false,
    hideToolbar = false,
    data,
    defaultPageSize = 20,
    defaultPageIndex = 0,
    defaultFilters = emptyArray,
    defaultSortBy = emptyArray,
    defaultHiddenColumns = emptyArray,
    loading = false,
    pageCount,
    load,
    initialState,
    onRowClick,
    renderRowSubComponent,
    totalCount,
    queryData,
    aggregateData,
    canPrint = true,
    canXLSXExport = true,
    canChangeColumnsVisibility = true,
    autoResetExpanded,
    onSelectedChange,
    disableFilters = false,
    disablePagination = false,
    error,
    getRowSx,
  },
  ref,
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useLocale();
  const [initialStateFromSettings, saveUserSettings] = useUserSettings<string>(`ReactTable-${CACHE_KEY(id)}`, null);

  const [storedState] = useState(() =>
    normalizeInitialState(
      initialState ||
        (id && !location.search && initialStateFromSettings ? JSON.parse(initialStateFromSettings) : null) ||
        calculateInitialState(
          id,
          urlPrefix,
          defaultPageIndex,
          defaultPageSize,
          defaultSortBy,
          defaultFilters,
          defaultHiddenColumns,
          location,
          columns,
        ),
      columns,
    ),
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: InputFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    headers,
    prepareRow,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    allColumns,
    setAllFilters,
    footerGroups,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
  } = useTable<(typeof data)[0]>(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: storedState,
      pageCount,
      manualPagination: Boolean(load),
      manualFilters: Boolean(load),
      manualSortBy: Boolean(load),
      stateReducer,
      totalCount,
      queryData,
      aggregateData,
      autoResetExpanded,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    useTableLayout,
    useRowSelect,
  );

  useImperativeHandle(
    ref,
    (): ReactTableRef<(typeof data)[0]> => ({
      setAllFilters,
    }),
  );

  useEffect(() => {
    if (id) {
      saveUserSettings(
        JSON.stringify({
          pageIndex: state.pageIndex,
          pageSize: state.pageSize,
          sortBy: state.sortBy,
          filters: state.filters,
          hiddenColumns: state.hiddenColumns,
        }),
      );
    }
  }, [state.pageIndex, state.pageSize, state.sortBy, state.filters, state.hiddenColumns, id, saveUserSettings]);

  useEffect(() => {
    // Schedule url update
    const handler = setTimeout(() => {
      const query = queryString.parse(location.search);
      // Remove filter values
      const newQuery = omit(
        query,
        Object.keys(query).filter(
          (key) =>
            key.startsWith(SORT_PREFIX(urlPrefix)) ||
            key.startsWith(FILTER_PREFIX(urlPrefix)) ||
            key.startsWith(WIDTH_PREFIX(urlPrefix)) ||
            key.startsWith(HIDDEN_PREFIX(urlPrefix)) ||
            key.startsWith(EXPANDED_PREFIX(urlPrefix)),
        ),
      );

      state.sortBy.forEach((sort) => {
        newQuery[`${SORT_PREFIX(urlPrefix)}${sort.id}`] = sort.desc ? 'desc' : 'asc';
      });

      Object.keys(state.columnResizing.columnWidths).forEach((column) => {
        const value = state.columnResizing.columnWidths[column];
        if (value) {
          newQuery[`${WIDTH_PREFIX(urlPrefix)}${column}`] = Math.round(value);
        }
      });

      state.filters.forEach(({ id, value }) => {
        if (value !== '' && value !== undefined && value !== null) {
          newQuery[`${FILTER_PREFIX(urlPrefix)}${id}`] = value;
        }
      });

      state.hiddenColumns.forEach((id) => {
        newQuery[`${HIDDEN_PREFIX(urlPrefix)}${id}`] = 1;
      });

      Object.keys(state.expanded).forEach((id) => {
        newQuery[`${EXPANDED_PREFIX(urlPrefix)}${id}`] = 1;
      });

      newQuery[PAGE(urlPrefix)] = `${state.pageIndex + 1}`;
      newQuery[PAGE_SIZE(urlPrefix)] = `${state.pageSize}`;

      const search = '?' + queryString.stringify(newQuery);
      if (location.search !== search && !ignoreUrl) {
        navigate(
          {
            ...location,
            search,
          },
          { replace: true, preventScrollReset: true, state: location.state },
        );
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [navigate, id, location, state, urlPrefix, ignoreUrl]);

  useEffect(() => {
    if (load) {
      load(state);
    }
  }, [state, load]);

  useEffect(() => {
    onSelectedChange?.(selectedFlatRows);
  }, [selectedFlatRows, onSelectedChange]);

  // Sync scrolling
  const headContainer = useRef<HTMLTableCellElement>();
  const bodyContainer = useRef<HTMLDivElement>();
  const theadSpacingRef = useRef<HTMLTableCellElement>();
  useEffect(() => {
    const head = headContainer.current;
    const body = bodyContainer.current;
    const table = body.querySelector('table');
    const tableHead = table.querySelector('thead');
    const spacing = theadSpacingRef.current;

    head.style.position = 'absolute';

    const topScrollbar = head;
    const bottomScrollbar = body;

    let ignoreScroll = false;
    const createHandler = (destination: HTMLDivElement, source: HTMLDivElement) => (): void => {
      const ignore = ignoreScroll;
      ignoreScroll = false;
      if (ignore) {
        return;
      }
      destination.scrollLeft = source.scrollLeft;
      ignoreScroll = true;
    };
    const topHandler = createHandler(bottomScrollbar, topScrollbar);
    const bottomHandler = createHandler(topScrollbar, bottomScrollbar);

    const resizeHandler = (): void => {
      const width = (body.firstChild as HTMLDivElement).offsetWidth;
      const height = tableHead.offsetHeight - (spacing.style.height !== '' ? 9 : 0);
      head.style.top = `${height}px`;
      (head.firstChild as HTMLDivElement).style.width = `${width}px`;
      (head.firstChild as HTMLDivElement).style.height = '1px';
      spacing.style.height = head.offsetWidth < width ? '9px' : '';
    };

    topScrollbar.addEventListener('scroll', topHandler);
    bottomScrollbar.addEventListener('scroll', bottomHandler);
    window.addEventListener('resize', resizeHandler);

    resizeHandler();

    return (): void => {
      topScrollbar.removeEventListener('scroll', topHandler);
      bottomScrollbar.removeEventListener('scroll', bottomHandler);
      window.removeEventListener('resize', resizeHandler);
      // head.style.position = '';
      // head.style.top = '';
      // spacing.style.height = '';
      // (head.firstChild as HTMLDivElement).style.width = '';
      // (head.firstChild as HTMLDivElement).style.height = '';
    };
  }, [headContainer, bodyContainer, headers.length, page]);

  const onReset = useEventCallback(() => {
    setAllFilters([]);
    gotoPage(1);
  });

  const onPrint = useEventCallback((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const clone = cloneTable(tableRef.current);

    const win = window.open('', '');
    win.document.close();

    const title = win.document.createElement('title');
    win.document.head.append(title);

    // Copy styles and links
    const styleElements = document.querySelectorAll('style');
    for (let i = 0; i < styleElements.length; i++) {
      const style = styleElements[i];
      const el = win.document.createElement('style');
      el.innerHTML = style.innerHTML;
      win.document.head.append(el);
    }

    const linkElements = document.querySelectorAll('link');
    for (let i = 0; i < linkElements.length; i++) {
      const link = linkElements[i];
      const el = win.document.createElement('link');
      el.type = link.type;
      el.href = link.href;
      win.document.head.append(el);
    }

    const symbolElements = document.querySelectorAll('symbol');
    for (let i = 0; i < symbolElements.length; i++) {
      const symbol = symbolElements[i];
      const el = win.document.createElement('svg');
      win.document.body.append(el);
      el.outerHTML = symbol.parentElement.outerHTML;
    }

    const printStyle = win.document.createElement('style');
    win.document.head.append(printStyle);
    printStyle.innerHTML = `body { background-color: #fff; }`;

    const clonedTable = win.document.createElement('table');
    win.document.body.append(clonedTable);
    clonedTable.outerHTML = clone.outerHTML;

    win.setTimeout(() => {
      win.print();
      win.close();
    }, 1000);
  });

  const [xlsxExporting, setXLSXExporting] = useState<boolean>(false);
  const tableRef = useRef<HTMLTableElement>();
  const onDownloadXLSX = useEventCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      setXLSXExporting(true);

      async function execute() {
        const { default: ExcelJS } = await import('exceljs' /* webpackChunkName: "xlsx" */);
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('Sheet1');

        const clone = cloneTable(tableRef.current);
        const visibleHeaders = headers.filter((h) => h.isVisible);

        ws.columns = visibleHeaders.map((h) => ({
          key: h.id,
          style: h['XLSXStyle']?.(),
        }));

        // Add headers
        const headersCells = clone.querySelectorAll(`.${classes.headerLabel}`);
        const headersRow = ws.addRow(
          visibleHeaders.map((h, i) => (h['XLSXHeader'] ? h['XLSXHeader']() : headersCells.item(i).textContent)),
        );
        headersRow.font = { bold: true };

        // Add rows
        const tableRows = clone.querySelectorAll(`[data-row]`);
        page.forEach((tableRow, i) => {
          const tableCells = tableRows.item(i).querySelectorAll(`[data-column]`);
          ws.addRow(
            tableRow.cells.map((c, j) => {
              const content = tableCells.item(j).textContent.trim();
              return c.column['XLSXCell']
                ? c.column['XLSXCell'](c)
                : /^-?\d+([,.]\d+)?$/.test(content) && content?.[0] !== '0'
                ? parseFloat(content.replace(',', '.'))
                : content;
            }),
          );
        });

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = 'export.xlsx';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);

        setXLSXExporting(false);
      }

      execute();
    },
  );

  const [showVisibilityModal, setShowVisibilityModal] = useState<boolean>(false);
  const onVisibility = useEventCallback(() => {
    setShowVisibilityModal(true);
  });
  const onVisibilityClose = useEventCallback(() => {
    setShowVisibilityModal(false);
  });

  return (
    <div>
      {!hideToolbar && (
        <Box sx={{ textAlign: 'right', mb: 1 }}>
          {!disableFilters && (
            <Link onClick={onReset} color="primary" component="button" underline="none">
              <FontAwesomeIcon icon={faFilter} /> {t(REACT_TABLE_RESET_FILTERS)}
            </Link>
          )}{' '}
          {canXLSXExport && (
            <Link
              component="button"
              onClick={xlsxExporting ? null : onDownloadXLSX}
              color="primary"
              sx={{ ml: '0.5em' }}
              underline="none"
            >
              <FontAwesomeIcon icon={faFileExcel} /> {t(REACT_TABLE_EXPORT_XLSX)}
            </Link>
          )}{' '}
          {canPrint && (
            <Link component="button" onClick={onPrint} color="primary" sx={{ ml: '0.5em' }} underline="none">
              <FontAwesomeIcon icon={faPrint} /> {t(REACT_TABLE_PRINT)}
            </Link>
          )}
          {canChangeColumnsVisibility && (
            <Link component="button" onClick={onVisibility} color="primary" sx={{ ml: '0.5em' }} underline="none">
              <FontAwesomeIcon icon={faEye} /> {t(REACT_TABLE_COLUMNS_VISIBLITY)}
            </Link>
          )}
        </Box>
      )}
      <Box sx={{ bgcolor: 'common.white', position: 'relative' }}>
        {loading && <Loading>{t(REACT_TABLE_LOADING)}</Loading>}
        <TableResponsive ref={headContainer}>
          <div />
        </TableResponsive>
        <TableResponsive ref={bodyContainer}>
          <Table
            {...getTableProps((props, { instance }) => ({
              style: {
                width: `${instance.totalColumnsWidth}px`,
              },
            }))}
            ref={tableRef}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // eslint-disable-next-line react/jsx-key
                    <HeaderCell {...column.getHeaderProps()}>
                      <HeaderCellContent
                        sortDirection={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : 'none'}
                      >
                        <HeaderLabel {...column.getSortByToggleProps()} className={classes.headerLabel}>
                          {column.render('Header')}
                        </HeaderLabel>
                        <HeaderResizer {...column.getResizerProps()} />
                      </HeaderCellContent>
                    </HeaderCell>
                  ))}
                </HeaderRow>
              ))}
              {!disableFilters && headerGroups.some((headerGroup) => headerGroup.headers.some((h) => h.canFilter)) && (
                <tr className={classes.filters}>
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) => (
                      // eslint-disable-next-line react/jsx-key
                      <FilterCell {...column.getHeaderProps()}>
                        {column.canFilter
                          ? column.render('Filter', { inputId: `selectFilter-${column.id}-first` })
                          : null}
                      </FilterCell>
                    )),
                  )}
                </tr>
              )}
              <tr>
                <TopScrollbar ref={theadSpacingRef} colSpan={headers.length} />
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Row
                    key={row.original.id as string}
                    row={row}
                    index={i}
                    onRowClick={onRowClick}
                    expanded={row.isExpanded}
                    renderRowSubComponent={renderRowSubComponent}
                    totalColumns={allColumns.length}
                    getRowSx={getRowSx}
                  />
                );
              })}
              {page.length === 0 && !loading && (
                <>
                  {error ? (
                    <tr>
                      <LoadingError colSpan={allColumns.length}>{error}</LoadingError>
                    </tr>
                  ) : (
                    <tr>
                      <NoResults colSpan={allColumns.length}>{t(REACT_TABLE_NO_RESULTS)}</NoResults>
                    </tr>
                  )}
                </>
              )}
            </tbody>
            <Footer>
              {footerGroups.map((group) => {
                const shouldRender = group.headers.some(
                  (column) => (columns.find((c) => c.id === column.id || c.accessor === column.id) as any)?.Footer,
                );
                if (!shouldRender) {
                  return null;
                }
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <FooterCell {...column.getFooterProps()}>{column.render('Footer')}</FooterCell>
                      );
                    })}
                  </tr>
                );
              })}
            </Footer>
          </Table>
        </TableResponsive>
        {!disablePagination && (
          <Pagination
            pages={pageOptions.length}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageIndex={state.pageIndex}
            pageSize={state.pageSize}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            totalCount={totalCount}
          />
        )}
      </Box>
      <VisibilityDialog
        open={showVisibilityModal}
        onClose={onVisibilityClose}
        allColumns={allColumns}
        toggleHideAllColumnsProps={getToggleHideAllColumnsProps()}
      />
    </div>
  );
};

ReactTable.displayName = 'ReactTable';

const ReactTableWithRef = React.forwardRef(ReactTable);

export default React.memo<Props>(ReactTableWithRef);

function stateToVariables<TData extends Record<string, any> = Record<string, any>>(
  state: Pick<TableState, 'pageSize' | 'pageIndex' | 'filters' | 'sortBy'>,
  columns: Column<TData>[],
  language: LocaleContextValue['language'],
) {
  return {
    limit: state.pageSize,
    offset: state.pageIndex * state.pageSize,
    order: state.sortBy
      .map((s) => {
        const column = columns.find((c) => c.id === s.id || c.accessor === s.id);
        const sortByToGraphQLVariable = column?.sortByToGraphQLVariable;
        let id: string | string[] = s.id;
        if (sortByToGraphQLVariable) {
          id = sortByToGraphQLVariable(language);
        }
        if (Array.isArray(id)) {
          return id.map((i) => `${i} ${s.desc ? 'DESC' : 'ASC'}`).join(', ');
        } else {
          return `${id} ${s.desc ? 'DESC' : 'ASC'}`;
        }
      })
      .join(', '),
    filter: state.filters.reduce((acc, cur) => {
      let column = columns.find((c) => c.id === cur.id);
      if (!column) {
        column = columns.find((c) => c.accessor === cur.id);
      }
      const filterToGraphQLVariable =
        column?.filterToGraphQLVariable ||
        (column?.Filter as any)?.filterToGraphQLVariable ||
        (column?.Filter as any)?.type?.filterToGraphQLVariable ||
        (InputFilter as any).filterToGraphQLVariable ||
        (InputFilter as any)?.type?.filterToGraphQLVariable;
      if (filterToGraphQLVariable) {
        const variable = filterToGraphQLVariable(cur.value, language, acc);
        if (variable !== undefined) {
          acc = set(acc, column?.filterToGraphQLVariableName || cur.id, variable);
        }
      } else {
        let value: string | boolean | number;
        if (/^\d+(\.\d+)?$/.test(cur.value)) {
          value = parseFloat(cur.value);
        } else if (/^(true|false)$/.test(cur.value)) {
          value = cur.value === 'true';
        } else {
          value = cur.value;
        }

        acc = set(acc, cur.id, value);
        // acc[cur.id.replace(/\./g, '_')] = value;
      }
      return acc;
    }, {}),
  };
}

export type GraphQLReactTableProps<
  TData extends Record<string, unknown> = Record<string, unknown>,
  TAggregate extends Record<string, unknown> = Record<string, unknown>,
> = Pick<Props<TData>, Exclude<keyof Props, 'loading' | 'data' | 'load' | 'pageCount' | 'initialState'>> & {
  query: DocumentNode;
  variables?: (variables: { [name: string]: any }) => { [name: string]: any };
  mapData: (data) => TData[];
  mapCount: (data) => number;
  mapAggregate?: (data) => TAggregate;
  skip?: (filters: Filters<TData>) => boolean;
};

const GraphQLReactTable: React.ForwardRefRenderFunction<
  ReactTableRef<ReturnType<GraphQLReactTableProps['mapData']>[0]>,
  GraphQLReactTableProps
> = (
  {
    query,
    variables,
    mapData,
    mapCount,
    mapAggregate,
    id,
    urlPrefix = '',
    defaultPageSize = 20,
    defaultPageIndex = 0,
    defaultFilters = emptyArray,
    defaultSortBy = emptyArray,
    defaultHiddenColumns = emptyArray,
    columns,
    skip,
    ...rest
  },
  ref,
) => {
  const location = useLocation();
  const { language } = useLocale();
  const [initialStateFromSettings] = useUserSettings(`ReactTable-${CACHE_KEY(id)}`, null);
  const [state, setState] = useState<TableState>(() =>
    normalizeInitialState(
      (id && !location.search && initialStateFromSettings ? JSON.parse(initialStateFromSettings) : null) ||
        calculateInitialState(
          id,
          urlPrefix,
          defaultPageIndex,
          defaultPageSize,
          defaultSortBy,
          defaultFilters,
          defaultHiddenColumns,
          location,
          columns,
        ),
      columns,
    ),
  );

  const autoResetExpanded = useRef<boolean>();

  const { pageIndex, pageSize, filters, sortBy } = state;
  const allVariables = useMemo(
    () =>
      variables
        ? variables(stateToVariables({ pageIndex, pageSize, filters, sortBy }, columns, language))
        : stateToVariables({ pageIndex, pageSize, filters, sortBy }, columns, language),
    [pageIndex, pageSize, filters, sortBy, columns, language, variables],
  );

  const { loading, data, error, previousData } = useQuery(query, {
    variables: allVariables,
    fetchPolicy: 'cache-and-network',
    skip: skip?.(filters) === true,
  });

  const onLoad = useCallback(
    (s) => {
      autoResetExpanded.current = true;
      setState(s);
    },
    [setState],
  );

  useEffect(() => {
    autoResetExpanded.current = false;
  });

  const load = useAsyncDebounce(onLoad, 50);

  const usedData = !data && loading && previousData ? previousData : data;
  const count = useMemo(() => (usedData ? mapCount(usedData) : 0), [mapCount, usedData]);
  const pages = Math.ceil(count / state.pageSize);
  const mappedData = useMemo(() => (usedData ? mapData(usedData) : []), [mapData, usedData]);
  const mappedAggregate = useMemo(
    () => (usedData && mapAggregate ? mapAggregate(usedData) : {}),
    [mapAggregate, usedData],
  );

  return (
    <ReactTableWithRef
      id={id}
      urlPrefix={urlPrefix}
      defaultFilters={defaultFilters}
      defaultPageIndex={defaultPageIndex}
      defaultPageSize={defaultPageSize}
      defaultSortBy={defaultSortBy}
      defaultHiddenColumns={defaultHiddenColumns}
      load={load}
      data={mappedData}
      pageCount={pages}
      loading={!data && loading}
      initialState={state}
      columns={columns}
      ref={ref}
      totalCount={count}
      queryData={data}
      error={error?.message}
      aggregateData={mappedAggregate}
      autoResetExpanded={autoResetExpanded.current}
      {...rest}
    />
  );
};

GraphQLReactTable.displayName = 'GraphQLReactTable';

const GraphQLReactTableWithRef = React.forwardRef(GraphQLReactTable);
const MemoizedGraphQLReactTableWithRef = React.memo(GraphQLReactTableWithRef);

export { MemoizedGraphQLReactTableWithRef as GraphQLReactTable };
